.gallery {
  background: url(/public/img/crowne_bg.png);
  background-repeat: no-repeat;
  padding-top: 15vh;
}

.gallery .gallery_container {
  padding-left: 9vh;
  padding-top: 8vh;
}

.swiper-slide {
  border-radius: 10px;
  display: flex;
  justify-content: center;
  opacity: 1;
  /*transition: 1s opacity;*/
  position: relative;
}
.swiper-slide-prev {
  opacity: 1;
}
.swiper-slide-active,
.swiper-slide-next {
  opacity: 1;
}

.swiper-slider {
  display: flex;
  justify-content: center !important;
}

.gallery .tab_one {
  border: 1px solid var(--pink);
  border-radius: 9px !important;
  margin-right: 2vh !important;
  color: black;
  text-align: center;
  font-size: 17px;
  cursor: pointer;
  padding: 2px 20px 9px 20px;
  height: 3vh;
  opacity: 0.5;
}
.gallery .tab_two {
  border: 1px solid var(--pink);
  border-radius: 9px !important;
  margin-right: 2vh !important;
  color: black;
  text-align: center;
  font-size: 17px;
  cursor: pointer;
  padding: 2px 20px 9px 20px;
  height: 3vh;
  opacity: 0.5;
}
.gallery .tab_three {
  border: 1px solid var(--pink);
  border-radius: 9px !important;
  margin-right: 2vh !important;
  color: black;
  text-align: center;
  font-size: 17px;
  cursor: pointer;
  padding: 2px 20px 9px 20px;
  height: 3vh;
  opacity: 0.5;
}
.gallery .tab_four {
  border: 1px solid var(--pink);
  border-radius: 9px !important;
  margin-right: 2vh !important;
  color: black;
  text-align: center;
  font-size: 17px;
  cursor: pointer;
  padding: 2px 20px 9px 20px;
  height: 3vh;
  opacity: 0.5;
}
.gallery .tab_five {
  border: 1px solid var(--pink);
  border-radius: 9px !important;
  margin-right: 2vh !important;
  color: black;
  text-align: center;
  font-size: 17px;
  cursor: pointer;
  padding: 2px 20px 9px 20px;
  height: 3vh;
  opacity: 0.5;
}
.gallery .tab_six {
  border: 1px solid var(--pink);
  border-radius: 9px !important;
  margin-right: 2vh !important;
  color: black;
  text-align: center;
  font-size: 17px;
  cursor: pointer;
  padding: 2px 20px 9px 20px;
  height: 3vh;
  opacity: 0.5;
}
.gallery .tab_seven {
  border: 1px solid var(--pink);
  border-radius: 9px !important;
  margin-right: 2vh !important;
  color: black;
  text-align: center;
  font-size: 17px;
  cursor: pointer;
  padding: 2px 20px 9px 20px;
  height: 3vh;
  opacity: 0.5;
}
.gallery .tab_eight {
  border: 1px solid var(--pink);
  border-radius: 9px !important;
  margin-right: 2vh !important;
  color: black;
  text-align: center;
  font-size: 17px;
  cursor: pointer;
  padding: 2px 20px 9px 20px;
  height: 3vh;
  opacity: 0.5;
}

.gallery .tab_list {
  padding-bottom: 5vh;
}

.one_ex {
  height: 50vh;
  border-radius: 10px;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/exterior/217.jpg);
}
.two_ex {
  height: 50vh;
  border-radius: 10px;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/exterior/149.jpg);
}
.three_ex {
  height: 50vh;
  border-radius: 10px;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/exterior/209.jpg);
}
.four_ex {
  height: 50vh;
  border-radius: 10px;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/exterior/205.jpg);
}
.five_ex {
  height: 50vh;
  border-radius: 10px;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/exterior/208.jpg);
}

.one_ro {
  height: 50vh;
  border-radius: 10px;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/rooms/18.jpg);
}

.two_ro {
  height: 50vh;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/rooms/King3.jpg);
}

.three_ro {
  height: 50vh;

  background-repeat: no-repeat !important;
  background-position: bottom !important;
  background-size: cover;
  background: url(/public/img/rooms/Kral4.jpg);
}

.four_ro {
  height: 50vh;

  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/rooms/Premi\ JSuite\ 4.jpg);
}

.five_ro {
  height: 50vh;

  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/rooms/Suite\ Bedroom2.jpg);
}
.one_me {
  height: 50vh;

  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/meeting/106.jpg);
}
.two_me {
  height: 50vh;

  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/meeting/124.jpg);
}
.three_me {
  height: 50vh;

  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/meeting/142.jpg);
}
.four_me {
  height: 50vh;

  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/meeting/48.jpg);
}
.five_me {
  height: 50vh;

  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/meeting/60.jpg);
}
.one_aura {
  height: 50vh;

  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/aura/DSC_7822.jpg);
}
.two_aura {
  height: 50vh;

  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/aura/211.jpg);
}
.three_aura {
  height: 50vh;

  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/aura/DSC_7797.jpg);
}
.four_aura {
  height: 50vh;

  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/aura/157.jpg);
}
.five_aura {
  height: 50vh;

  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/aura/160.jpg);
}
.one_one {
  height: 50vh;

  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/one/166.jpg);
}
.two_one {
  height: 50vh;

  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/one/167.jpg);
}
.three_one {
  height: 50vh;

  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/one/168.jpg);
}
.four_one {
  height: 50vh;

  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/one/169.jpg);
}
.five_one {
  height: 50vh;

  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/one/170.jpg);
}
.one_spa {
  height: 50vh;

  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/spa/1002.jpg);
}
.two_spa {
  height: 50vh;

  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/spa/1003.jpg);
}
.three_spa {
  height: 50vh;

  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/spa/1004.jpg);
}
.four_spa {
  height: 50vh;

  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/spa/1008.jpg);
}
.five_spa {
  height: 50vh;

  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/spa/DSC_8055.jpg);
}

.one_all {
  height: 50vh;

  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/all/159.jpg);
}
.two_all {
  height: 50vh;

  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/all/167.jpg);
}
.three_all {
  height: 50vh;

  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/all/63.jpg);
}
.four_all {
  height: 50vh;

  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/all/DSC_7495.jpg);
}
.five_all {
  height: 50vh;

  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/all/Premi\ DQueen3.jpg);
}

.one_int {
  height: 50vh;

  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/interior/101.jpg);
}
.two_int {
  height: 50vh;

  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/interior/161.jpg);
}
.three_int {
  height: 50vh;

  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/interior/165.jpg);
}
.four_int {
  height: 50vh;

  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/interior/DSC_7563.jpg);
}
.five_int {
  height: 50vh;

  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover;
  background: url(/public/img/interior/wedding_angora.png);
}

.swiper-scrollbar-drag {
  height: 5vh !important;

  background-color: var(--pink);
}

.swiper-scrollbar {
  background-color: white;
  border: 1px solid var(--pink);
  width: 1vh !important;
  height: 70% !important;
}

.swiper-scrollbar.swiper-scrollbar-vertical,
.swiper-vertical > .swiper-scrollbar {
  z-index: 0 !important;
  right: 0vh !important;
  top: 10vh !important;
}

.gallery .swiper-slide {
  display: flex;
  justify-content: center;
  width: 85%;
  box-shadow: 15px 16px 19px -7px gray;
}

.gallery .swiper-wrapper {
  margin-bottom: -170vh !important;
}

.gallery .tab_one {
  min-width: 30vh;
  border: 1px solid var(--pink);
  border-radius: 9px !important;
  margin-right: 2vh !important;
  color: black;
  opacity: 0.5;
  text-align: center;
  font-size: 17px;
  cursor: pointer;
  padding: 2px 20px 9px 20px;
  height: 3vh;
}

.gallery .react-tabs__tab--selected {
  cursor: pointer;
  background: var(--pink);
  border: none !important;
  border-color: transparent !important;
  color: black;
  font-weight: 600;
  opacity: 1;
  border-radius: 5px 5px 5px 5px;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1708px) {
  .gallery {
    padding-top: 16vh;
  }
  .gallery .tab_eight {
    height: 4vh;
  }
  .gallery .tab_seven {
    height: 4vh;
  }

  .gallery .tab_six {
    height: 4vh;
  }
  .gallery .tab_five {
    height: 4vh;
  }
  .gallery .tab_four {
    height: 4vh;
  }
  .gallery .tab_three {
    height: 4vh;
  }
  .gallery .tab_two {
    height: 4vh;
  }
  .gallery .tab_one {
    height: 4vh;
  }

  .gallery .swiper-wrapper {
    margin-bottom: -170vh !important;
  }
  .one {
    height: 50vh;
  }

  .two {
    height: 50vh;
  }

  .three {
    height: 50vh;
  }

  .four {
    height: 50vh;
  }

  .five {
    height: 50vh;
  }

  .gallery .gallery_container {
    padding-top: 0vh;
    position: relative;
    top: -10vh;
  }
}

@media screen and (max-width: 992px) {
  .gallery .gallery_container {
    padding-top: 0vh;
    position: relative;
    top: 0vh;
  }

  .gallery .tab_list {
    overflow-x: scroll;
  }

  .swiper-scrollbar.swiper-scrollbar-vertical,
  .swiper-vertical > .swiper-scrollbar {
    z-index: 0 !important;
    right: 3vh !important;
    top: 10vh !important;
  }

  .gallery .swiper-slide {
    width: 80%;
  }

  .gallery .swiper-wrapper {
    margin-bottom: -190vh !important;
  }
}

@media screen and (max-width: 568px) {
  .paginator-container{
    gap: 2px !important;
  }
  .gallery .tab_list {
    padding-bottom: 5vh;
  }

  .swiper-scrollbar.swiper-scrollbar-vertical,
  .swiper-vertical > .swiper-scrollbar {
    z-index: 0 !important;
    right: 2vh !important;
    top: 10vh !important;
  }
  .gallery .swiper-wrapper {
    margin-bottom: -170vh !important;

    align-items: center;
  }

  .gallery .gallery_container {
    padding-left: 0;
  }

  .gallery .tab_list {
    right: 0;
  }

  .gallery .tab_one {
    min-width: 50%;
  }
  .gallery .tab_two {
    min-width: 50%;
  }
  .gallery .tab_three {
    min-width: 50%;
  }
  .gallery .tab_four {
    min-width: 50%;
  }
  .gallery .tab_five {
    min-width: 50%;
  }
  .gallery .tab_six {
    min-width: 50%;
  }

  .gallery .tab_seven {
    min-width: 50%;
  }
  .gallery .tab_eight {
    min-width: 50%;
  }
}
/* .image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  grid-gap: 0;
  padding: 0;
  margin: 0;
}

.image-item {
  cursor: pointer;
  width: 100%;
  padding: 0;
  margin: 0;
}

.image-item img {
  width: 100%;
  max-width: 100%;
  height: auto;
  transition: filter 0.6s;
}

.image-item img:hover {
  filter: brightness(0.7);
} */

.categories {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.categories button {
  background-color: transparent;
  border: none;
  margin: 0 10px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
  color: #000;
  cursor: pointer;
  transition: color 0.3s;
}

.categories button.active {
  color: #ff69b4;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.image-grid .image-item {
  position: relative;
}

.image-grid .image-item img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: opacity 0.3s;
}

.image-grid .image-item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.3s;
  cursor: pointer;
}

.image-grid .image-item:hover:before {
  opacity: 1;
}

.image-grid .image-item:hover img {
  opacity: 0.7;
}

.image-grid .category-title {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.lightbox-toolbar {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999;
}

.lightbox-close {
  background-color: #fff;
  border: none;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
  color: #000;
  cursor: pointer;
}

.filter-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.filter-buttons button {
  padding: 8px 16px;
  margin: 0 5px;
  border: none;
  cursor: pointer;
  transition: 1s all ease;
}

.filter-buttons button.active {
  border-bottom: 1px solid #9c0d6b;
  color: black;
}

.styles_lightroom__1X2qE {
  height: 100vh;
  width: 100vw;
  position: fixed !important;
  z-index: 99999999 !important;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: all 0.15s;
  visibility: hidden;
}

.styles_lightroom__1X2qE {
  position: fixed !important;
}

.styles_carouselcontrolnext__DoQ9- {
  display: none !important;
}

.styles_carouselcontrolprev__Bmyua {
  display: none !important;
}

.styles_thumbpanel__1sa4E {
  z-index: 9999999999;
}

.btn-filters {
  transition: 1s all ease;
  gap: 50px;
}

.styles_column__2r45X img {
  cursor: pointer;
}

.btn-filter.active {
  text-decoration: underline;
  text-decoration-color: #9c0d6b !important;
  -moz-text-decoration-color: #9c0d6b !important;
}

.paginator-container{
  gap: 20px;
}




.styles_lightroomcontent__1SCaZ {
 
  align-items: end !important;
}

.gallery-container {
  transition: opacity 2s ease;
}

.gallery-container.lightroom-loading {
  opacity: 0.8;
}

.gallery-transition {
  opacity: 0.5;
  transition: opacity 0.5s ease;
}

.gallery-transition.show {
  opacity: 1;
}

.btn-filters{
  gap: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}


 .btn-filters .btn-filter {
  text-transform: capitalize;
  letter-spacing: 1px;
  font-weight: 300;
  color: black;
  padding: 0vh 2vh;
}

 .btn-filters .btn-filter.active {
  color: #9c0d6b;
  text-decoration-color: #9c0d6b !important;
  -moz-text-decoration-color: #9c0d6b !important;
}

.pagination-button {

  color: var(--black);
  margin: 3px;
  padding: 3px 5px;
}

.pagination-button.active, .pagination-button:hover {
  background-color: var(--gold);
  color: #9c0d6b;
  font-weight: bold;
}