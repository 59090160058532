.date_picker-1 .react-datepicker {
  background-color: #9c0d6b !important;
  color: white !important;
  position: absolute !important;
  top: 5vh !important;
  padding: 4vh;
  right: 60vh;
  z-index: 999999999;
}
.react-datepicker {
  background-color: #9c0d6b !important;
  color: white !important;
  position: absolute !important;
  top: 11vh;
  padding: 4vh;
  right: 1vh;
  z-index: 999999999;
}




.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
  color: white !important;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
  color:white !important

}
.mobile_style {
  width: 25%;
}
.react-datepicker__header {
  background-color: #9c0d6b !important;
}

.btn-incr {
  border: 1px solid #9c0d6b;
  border-radius: 5px;
  height: 4vh;
  color: white;
  padding-top: 0px;
}

.text-end {
  text-align: end;
}

.text-center {
  text-align: center;
}

.btn-decr {
  border: 1px solid #9c0d6b;
  border-radius: 5px;
  height: 4vh;
  color: white;
  padding-top: 0px;
}

.datepicker_btn {
  backdrop-filter: blur(150px);
  background-color: white !important;
  color: #9c0d6b;
  border-radius: 5px;
}

.datepicker_btn a {
  color: #9c0d6b !important;
}

.datepicker_btn:hover {
  color: #9c0d6b;
}
.logo{
  width: 20%;
}
.header_logo{
  width: 10vw;
}

.book_now_title_one{
  text-align: center;
}

.btn-incr h5{
  color: white;
}


.btn-decr h5{
  color: white;
}

.react-datepicker__navigation--previous {
  top: 50px !important;
}

.react-datepicker__navigation--next {
  top: 50px !important;
}

@media screen and (max-width: 1258.98px) {
  .header_logo{
    display: none;
  }
}

@media screen and (max-width: 991.98px) {

  .social_media_icon_contact{
    font-size: 25px;
  }

  .header_social_media_icon{
    
  }
  .header_logo {
    width: 20vw;
}

.react-datepicker__navigation--previous {
  top: 55px !important;
}

.react-datepicker__navigation--next {
  top: 55px !important;
}

.react-datepicker {

  top: 3.5vh;
  right: 1vh;

}

.center_picker{
  justify-content: center;
}

}

@media screen and (max-width: 567.98px) {
  .react-datepicker__navigation--previous {
    top: 30px !important;
  }
  
  .react-datepicker__navigation--next {
    top: 30px !important;
  }

  .book_now_title_one{
    text-align: center;
    font-size: 16px;
  }


  .res_adult_book{
    display: flex;
    justify-content: center;
  }
  .res_child_book{
    display: flex;
    justify-content: center;
  }

  
  .datepicker_btn{
    font-size: 13px;
  }
   
  
  .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    margin-top: 0;
    color: #000;
    font-weight: bold;
    font-size: 0.844rem;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.1rem;
}



  .react-datepicker {
    background-color: #9c0d6b !important;
    color: white !important;
    position: absolute !important;
    top: 4.5vh;
    padding: 2vh;
    right: 1vh;
    z-index: 999999999;
}


.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding: 0px 0;
  position: relative;
}


  .header_logo{
    width: 30vw;
    margin-left: 9vh;
  }
  .datepicker_btn{
    box-shadow: 0px 1px 19px -10px gray;
   
  }
  .language{
    z-index: 99;
  }
  .header_gif {
    display: none;
  }
  .header_gif_two {
    display: none;
  }
  .mobile_style {
    width: auto;
    position: absolute;
    right: 0;
  }
    .header-second {
      display: block;
      color: white;
      position: fixed;
      top: 2vh;
      left: 0;
      z-index: 3;
      width: 100%;
  }}