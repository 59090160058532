body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Raleway", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm{
  background-color: #9c0d6b !important;
}
div:where(.swal2-icon).swal2-warning{
  color: #9c0d6b !important;
  border-color: #9c0d6b !important;
}
div:where(.swal2-icon){
  width: 3em !important;
  height: 3em !important;
}
div:where(.swal2-container) h2:where(.swal2-title){
  font-size: 1.05em !important;
}
div:where(.swal2-container) .swal2-html-container{
  font-size: 0.8em !important;
}
div:where(.swal2-container) button:where(.swal2-styled){
  padding: 0.5em 1em !important;
}
div:where(.swal2-icon) .swal2-icon-content{
  font-size: 3em !important;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm{
  font-size: 0.7em !important;
}
/* .custom-swal-popup {
  width: 50% !important; 
  max-width: 80%;  } */