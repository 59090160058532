.header_two{
    background-color: transparent;
}

.header_two .container{
    background-color: transparent;
}

.header_two_logo{
  
width: 10vw;

}
.header_two_nav .nav-link[data-single="true"]{
    color: white;
    font-weight: 400;
}


/* .header_nav .nav-link:hover {
    border-bottom: 1px solid white;
  } */

  .header_scroll {
    background-color: rgba(255, 255, 255, 0.822); /* Örnek arka plan rengi */
  }
  .header_scroll_mobile{
    background-color: #9c0d6b;
    width: 10%;
    border-radius: 90%;
    -webkit-border-radius: 90%;
    -moz-border-radius: 90%;
    -ms-border-radius: 90%;
    -o-border-radius: 90%;
}

  .pink_links .nav-item_text {
    color: #9c0d6b; /* Örnek link rengi */
  }
  
  .header_two_logo{
    filter: brightness(0) invert(1);
  }

  .no_filter {
    filter: none; /* Filtre özelliği olmadan resmi göstermek için */
  }

  .scroll_header_nav-list_item:hover {
    border-bottom: 1px solid #9c0d6b; /* Scroll yapıldığında linklere hover olduğunda kullanılacak stil */
  }

  .header_scroll .header_gif {
  border-radius: 90px;
  background-color: #9c0d6b;
}

.header-second {
  display: none;
 color: white;
  position: fixed;
  top: 0;
 left:0;
  z-index: 3;
  width: 100%;
}
.mobile_style {
  width: 25%;
}


.header-second ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  background-color: #fff;
}

.header-second li a {
  color: #9c0d6b;
  display: block;
  padding: 20px 20px;
  border-right: 1px solid #f4f4f4;
  text-decoration: none;
}

.header-second li a:hover,
.header-second .menu-btn:hover {
  background-color: #f4f4f4;
}

.header-second .logo {
  display: block;
  float: left;
  font-size: 2em;
  padding: 10px 20px;
  text-decoration: none;
}



.header-second .menu-second {
  clear: both;
  max-height: 0;
  transition: max-height .2s ease-out;
}



.header-second .menu-icon {
  cursor: pointer;
  display: inline-block;
 
  position: relative;
  user-select: none;
  
}

.header-second .menu-icon .navicon {
  background:#9c0d6b;
  display: block;
  height: 2px;
  position: relative;
  transition: background .2s ease-out;
  width: 18px;
}

.header-second .menu-icon .navicon:before,
.header-second .menu-icon .navicon:after {
  background: #9c0d6b;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}

.header-second .menu-icon .navicon:before {
  top: 5px;
}

.header-second .menu-icon .navicon:after {
  top: -5px;
}



.header-second .menu-btn {
  display: none;
}

.header-second .menu-btn:checked ~ .menu-second                                                                                                                                                                                                                                   {
  background: white;
  max-height: 100vh;
  width: 100%;
  min-height: 105vh;
}
.contact_crowne_logo_menu{
  width: 20%;
}
.header-second .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.header-second .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header-second .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header-second .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header-second .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}
.menu-btn:checked + .header-second {
  background-color: red;
  background: red;
}



@media (min-width: 100em) {
  .header-second li {
    float: left;
  }
  .header-second li a {
    padding: 20px 30px;
  }
  .header-second .menu-second {
    clear: none;
    float: right;
    max-height: none;
  }
  .header-second .menu-icon {
    display: none;
  }
}

@media screen and (max-width: 1258.98px) {


  .medium-screen{
    display: flex;
  }

  .medium-screen{
    display: flex;
  }
  .header_two{
    position: fixed;
  }
  .header{
    position: fixed;
  }
  .header_two_logo{
    display: none;
  
   
    /* width: 30vw;
    margin-left: 8vh; */
  }
  .language{
    z-index: 999;
  }
  .header-second .menu-btn:checked ~ .menu-second{
    margin-top: -6vh;
  }



  .header-second .menu-icon .navicon {
    width: 30px;
   
  }
   .header_gif {
    display: none;
  }
  .header_gif_two {
    display: none;
  }
.mobile_style {
  width: auto;
   position: absolute;
    right: 0;
}
.menu-second{
  box-shadow: 0px 1px 19px -10px gray;
}
.menu-second li:nth-child(1){
 margin-top: 10vh;
}
.header-second li a{
  font-size: 24px;
  padding: 5px 20px;
  display: flex;
  justify-content: center;
}
  .header-second {
  
    display: block;
    color: white;
    position: fixed;
    top: 2vh;
    left: 0;
    z-index: 3;
    width: 100%;
}
}



@media screen and (max-width: 991.98px) {

  .medium-screen{
    display: block;
  }

  .header_scroll_mobile {
    width: 5%;
  
}

.header-second li a {
  font-size: 20px;
 
}
}

@media screen and (max-width: 567.98px) {
  .header-second li a {
    font-size: 15px;
  }

  .tel_link{
    font-size: 13px !important;
  }
}

