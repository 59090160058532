@import "~mdb-ui-kit/css/mdb.min.css";

.rooms {
  padding-top: 20vh;
  background: url(/public/img/crowne_bg.png);
}

/* .ril__toolbar {
  top: 15% !important;
  height: 50px;
} */

.rooms .section_header-title {
  position: relative;
  top: -10vh;
}

.crowne-main img {
  cursor: pointer;
  border-radius: 5px;
  transition: 1s all ease !important;
  display: block;
  width: 45%;
  object-fit: cover;
}

.crowne-main .room_card .room_card_bg {
  transition: 1s all ease;
  -webkit-transition: 1s all ease;
  -moz-transition: 1s all ease;
  -ms-transition: 1s all ease;
  -o-transition: 1s all ease;
}

.crowne-main .room_card .room_card_bg {
  transition: 1s all ease;
  -webkit-transition: 1s all ease;
  -moz-transition: 1s all ease;
  -ms-transition: 1s all ease;
  -o-transition: 1s all ease;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  grid-gap: 0;
  padding: 0;
  margin: 0;
}

.image-item {
  cursor: pointer;
  width: 100%;
  padding: 0;
  margin: 0;
}

.image-item img {
  width: 100%;
  max-width: 100%;
  height: auto;
  transition: filter 0.6s;
}

.image-item img:hover {
  filter: brightness(0.7);
}

.rooms .section_header-subtitle {
  position: relative;
  top: -10vh;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.13);
  /* Ekranı hafifçe karartmak için yarı saydam bir arka plan rengi */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup-content {
  background-color: #fff;
  /* Popup içeriğinin arka plan rengi */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.096);
}

.card {
  padding: 40px;
}

.rooms .services_div {
  padding-top: 5vh;
}

.back_btn {
  background-color: white;
  border: 2px solid var(--pink);
  border-radius: 5px;
  padding: 3px;
  height: 5vh;
}

.arrow_back_room {
  color: var(--pink);
}

.rooms_detail_header {
  display: flex;
  justify-content: space-between;
}

.tab_list {
  padding-top: 1vh;
  padding-bottom: 1vh;
  display: flex;
  justify-content: start;
  border-bottom: 1px solid var(--black);
  position: relative;
  right: 0vh;
}

.tab_one {
  transition: 1s all ease;

  width: 30vh;
  font-size: 20px;
  opacity: 0.5;
  cursor: pointer;
}

.tab_two {
  width: 25vh;
  transition: 1s all ease;

  font-size: 20px;
  cursor: pointer;
  opacity: 0.5;

  padding: 0 2vh;
}

.tab_three {
  transition: 1s all ease;

  width: 40vh;

  font-size: 20px;
  cursor: pointer;
  opacity: 0.5;

  padding: 0 2vh;
}

.tab_one_inner {
  font-size: 1em;
  cursor: pointer;
  opacity: 0.5;

  padding: 0 2vh;
}

.tab_two_inner {
  font-size: 1em;
  cursor: pointer;
  opacity: 0.5;

  padding: 0 2vh;
}

.tab_three_inner {
  font-size: 1em;
  cursor: pointer;
  opacity: 0.5;

  padding: 0 2vh;
}

.tab_four_inner {
  font-size: 1em;
  cursor: pointer;
  opacity: 0.5;

  padding: 0 2vh;
}

.room_inner_btn {
  background-color: var(--white);
  border: 1px solid var(--pink);
}

.room_pink_tab {
  padding: -2px 35px -2px 35px !important;
  margin-right: 2vh !important;
  color: black;
}

.room_tab_pink {
  padding: -2px 35px -2px 35px !important;
  margin-right: 2vh !important;
  color: black;
  text-align: center;
}

.room_tab_detail .react-tabs__tab--selected {
  background-color: var(--pink);
  color: white;
}

.css-1gsv261 {
  border-bottom: none !important;
  border-color: rgba(0, 0, 0, 0.12);
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  font-size: 14px !important;
}

.room_pink_tab.selected {
  /* Seçili olduğu durumun stilini burada belirleyebilirsiniz */
  background-color: var(--pink);
  color: white;
}

.css-1aquho2-MuiTabs-indicator {
  background-color: transparent !important;
}

.react-tabs__tab--selected {
  cursor: pointer;
  background: transparent;
  border: none !important;
  border-color: transparent !important;
  color: black;
  opacity: 1;
  border-radius: 5px 5px 0 0;
}

.text {
  font-size: 2.5rem;
  line-height: 1.5em;
  word-spacing: 0.5em;
  letter-spacing: 0.1em;
}

.title {
  font-size: 3rem;
  line-height: 1.5em;
  word-spacing: 0.5em;
  letter-spacing: 0.1em;

  color: var(--icon-hover-bg);
  padding: 10px;
}

.heading {
  font-size: 6rem;
  line-height: 1.5em;
  word-spacing: 0.5em;
  letter-spacing: 0.1em;
}

.centre-text {
  text-align: center;
}

.bold-text {
  font-weight: bold;
}

.room_inner {
  padding-top: 0vh;
  display: flex;
  justify-content: space-around;
}

.item_one_room {
  min-height: 65vh;
  background: white;
  box-shadow: 0px 1px 19px -10px gray;
  border-radius: 9px;
}
.item_one_room:hover {
  box-shadow: 0px 1px 36px -10px #413f3f;
}
.item_two_room:hover {
  box-shadow: 0px 1px 36px -10px #413f3f;
}
.item_three_room:hover {
  box-shadow: 0px 1px 36px -10px #413f3f;
}

.item_two_room {
  min-height: 65vh;
  background: white;
  box-shadow: 0px 1px 19px -10px gray;

  border-radius: 9px;
}

.item_three_room {
  min-height: 65vh;
  background: white;
  box-shadow: 0px 1px 19px -10px gray;

  border-radius: 9px;
}

.reset {
  border: 1px solid var(--pink);
  border-radius: 9px;
  height: 3vh;
  padding: 1px;
  position: relative;
  right: 14vh;
}

.arrow_back {
  color: var(--pink);
  font-size: 12px;
}

.room_card_standard {
  display: flex;
  padding: 20px;
}

.room_card {
  padding: 40px;
  transition: 1s all ease;
  cursor: pointer;
}

.room_card .room_card_bg {
  transition: 1s all ease;
}

/* .room_card:hover .room_card_bg {
  scale: 1.1;
}
.room_card .room_card_bg_two{
  transition: 1s all ease;
} */
/* .room_card:hover .room_card_bg_two {
  scale: 1.1;
}
.room_card .room_card_bg_three{
  transition: 1s all ease;
}
.room_card:hover .room_card_bg_three {
  scale: 1.1;
} */

.rooms .room_card p {
  font-size: 14px;
  padding-top: 2vh;
}

.rooms_btn {
  padding: 2px 11vh 2px 11vh;
  background-color: #9c0d6b;
  color: white;
  width: 100%;
}

/* .rooms_btn:hover {
  background-color: var(--pink);
  color: white;
} */

.rooms_btn_ihg {
  padding: 2px 11vh 2px 11vh;
  background-color: #9c0d6b;
  color: white !important;
}
.rooms_btn_ihg:hover {
  padding: 2px 11vh 2px 11vh;
  background-color: #9c0d6b;
  color: white !important;
}

/* 
.rooms_btn_ihg:hover {
  background-color: var(--pink);
  color: white;
} */

.room_card_bg {
  width: 100%;
  height: 35vh;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
  background-image: url(/public/img/king_room.png);
}

.room_card_bg_two {
  width: 100%;
  height: 35vh;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
  background-image: url(/public/img/suite_room.png);
}

.room_card_bg_three {
  width: 100%;
  height: 35vh;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
  background-image: url(/public/img/lounge.png);
}

.room_bg {
  border-radius: 7px;
  background-size: cover;
  background-position: center;
  width: 70vh;
  height: 40vh;
  background-image: url(/public/img/king_room.png);
}

.room_bg_two {
  border-radius: 9px;
  background-size: cover;
  background-position: center;
  width: 70vh;
  height: 40vh;
  background-image: url(/public/img/suite_room.png);
}

.room_bg_two_jr {
  border-radius: 9px;
  background-size: cover;
  background-position: center;
  width: 70vh;
  height: 40vh;
  background-image: url(/public/img/j_suite.png);
}

.room_bg_suite {
  border-radius: 9px;
  background-size: cover;
  background-position: center;
  width: 70vh;
  height: 40vh;
  background-image: url(/public/img/suite_bedroom.png);
}

.room_bg_king {
  border-radius: 9px;
  background-size: cover;
  background-position: center;
  width: 70vh;
  height: 40vh;
  background-image: url(/public/img/kral.png);
}

.room_bg_twin {
  border-radius: 9px;
  background-size: cover;
  background-position: center;
  width: 70vh;
  height: 40vh;
  background-image: url(/public/img/twin.png);
}

.room_bg_queen {
  border-radius: 9px;
  background-size: cover;
  background-position: center;
  width: 70vh;
  height: 40vh;
  background-image: url(/public/img/d_queen_bg.png);
}

.room_bg_engelli {
  border-radius: 9px;
  background-size: cover;
  background-position: center;
  width: 70vh;
  height: 40vh;
  background-image: url(/public/img/engelli.png);
}

.room_bg_lounge {
  border-radius: 9px;
  background-size: cover;
  background-position: center;
  width: 70vh;
  height: 46vh;
  background-image: url(/public/img/lounge.png);
}

.room_bg_three {
  background-size: cover;
  background-position: center;
  width: 70vh;
  height: 32vh;
  background-image: url(/public/img/lounge.png);
}

.tab_header {
  min-height: 15vh;
  display: flex;
  justify-content: space-between;
  padding-left: 3vh;
  padding-right: 3vh;
  padding-top: 2vh;
}

.css-19kzrtu {
  padding: 0 !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  min-height: 30px !important;
}

/* .swiper_box_shadow{
  box-shadow: 4px 0px 9px 2px black;
} */

/* .rooms .swiper {
  width: 71%;
  position: relative;
  right: 5vh;
}

.rooms .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
}

.rooms .swiper-slide img {
  border-radius: 5px;
  cursor: pointer;
  display: block;
  width: 100%;
  object-fit: cover;
  transition: 1s all ease !important;
}

.rooms .swiper-slide img:hover {
  scale: 1.2;
} */

.services {
  padding: 2vh;
  border-radius: 9px;
  box-shadow: 0px 1px 19px -10px gray;
  background-color: white;
}

.services_inner {
  display: flex;
  justify-content: space-around;
}

.services_icon {
  width: 30%;
}

.rooms_icons_section {
  display: flex;
  position: relative;
  left: 5.5vh;
  top: 3vh;
}

.rooms_lounge_text_section {
  position: relative;
  left: 6vh;
}

.rooms_lounge_text_section p {
  font-size: 13px;
}

.three_icon {
  width: 84%;
}

.one_icon {
  width: 87%;
}

.rooms_icons_section img {
  padding: 2.5vh;
  transition: 1s all ease;
}

.rooms_icons_section img:hover {
  opacity: 0.5;
}

.rooms .rooms_icons_section h6 {
  font-size: 13px;
  text-align: center;
}

.rooms .membership rooms_btn_ihg .white_link {
  color: white;
}

.room_text_section {
  display: flex;
  width: 100%;
  position: relative;
  top: 4vh;
  left: 4vh;
  gap: 30px;
  text-align: start;
}

.room_text_section_lounge {
  display: flex;
  width: 58vh;
  position: relative;
  top: 0vh;
  left: 0vh;
  text-align: start;
}

.room_text_section_lounge li {
  font-size: 15px;
  padding-bottom: 10px;
}

.room_text_section_engelli {
  width: 58vh;
  position: relative;
  top: 4vh;
  left: 6vh;
  text-align: start;
}

.room_text_section p {
  font-size: 13px;
}

.membership {
  margin-top: 10vh;
  padding: 3vh;
  border-radius: 9px;
  box-shadow: 0px 1px 19px -10px gray;
  background-color: white;
}

.rooms_btn_div {
  padding-top: 3rem;
}

.room_card_three_inner_header {
  width: 100%;
}

/* .room_card_three_inner_header p {
  padding-top: 2vh;
  padding-bottom: 2vh;
} */

.ihg_one {
  width: 80%;
}

.rooms_swiper_mobile_block {
  display: none;
}

.react-tabs {
  width: 100%;
}

.tab_one_room {
  transition: 1s all ease;
  opacity: 0.5;

  min-width: 30vh;
  font-size: 17px;
  cursor: pointer;
  padding: 2px 20px 9px 20px;
  height: 3vh;
}

.tab_two_room {
  transition: 1s all ease;
  min-width: 30vh;
  font-size: 17px;
  cursor: pointer;
  opacity: 0.5;
  padding: 2px 20px 9px 20px;
  height: 3vh;
}

.tab_three_room {
  transition: 1s all ease;
  opacity: 0.5;

  min-width: 30vh;
  font-size: 17px;
  cursor: pointer;
  padding: 2px 20px 9px 20px;
  height: 3vh;
}

.tab_four_room {
  transition: 1s all ease;
  opacity: 0.5;
  min-width: 10vh;
  font-size: 17px;
  cursor: pointer;
  padding: 2px 20px 9px 20px;
  height: 3vh;
}

.room_card_inner h6 {
  font-size: 22px;
}

.room_card_three_inner_header h6 {
  font-size: 21px;
}

.hero_main_premium {
  max-width: 700px;
}

@media screen and (min-width: 2560px) {



  .rooms .room_card p {
    font-size: 19px;
  }

  .services_icon {
    padding: 4vh;
    width: 100%;
  }

  .item_three_room {
    width: 100% !important;
    height: auto;
  }

  .item_two_room {
    width: 100% !important;
    height: auto;
  }

  .item_one_room {
    width: 100% !important;
    height: auto;
  }

  .room_inner {
    gap: 40px;
  }

  .rooms_btn {
    width: 100%;
    font-size: 20px;
  }

  .rooms_btn_ihg {
    font-size: 20px;
  }

  .membership_inner h4 {
    font-size: 30px;
  }

  .room_card_bg_three {
    width: 100%;
  }

  .room_card_bg_two {
    width: 100%;
  }

  .room_card_bg {
    width: 100%;
  }

  .reset {
    right: -1vh;
  }

  .room_text_section li {
    font-size: 17px;
  }

  .rooms .rooms_icons_section h6 {
    font-size: 14px;
  }

  .rooms .swiper {
    right: -1vh;
  }

  .tab_four_room h6 {
    font-size: 22px;
  }

  .tab_three_room h6 {
    font-size: 22px;
  }

  .tab_two_room h6 {
    font-size: 22px;
  }

  .tab_one_room h6 {
    font-size: 22px;
  }

  .tab_three {
    font-size: 32px;
  }

  .tab_two {
    font-size: 32px;
  }

  .tab_one {
    font-size: 32px;
  }

  .res_breakfast {
    position: relative;
    left: 3vh;
  }

  .res_breakfast h6 {
    font-size: 20px;
  }

  .room_text_section_lounge {
    left: 3vh;
  }

  .room_text_section_lounge h6 {
    font-size: 20px;
  }

  .room_text_section_lounge li {
    font-size: 17px;
  }

  .rooms_lounge_text_section p {
    font-size: 17px;
  }
}

@media screen and (max-width: 1708px) {
  .rooms {
    padding-top: 30vh;
  }

  .tab_one_room {
    width: 41vh;
    height: 4vh;
  }

  .tab_one_room h6 {
    font-size: 16px;
  }

  .tab_two_room h6 {
    font-size: 16px;
  }

  .tab_three_room h6 {
    font-size: 16px;
  }

  .tab_four_room h6 {
    font-size: 16px;
  }

  .tab_two_room {
    width: 41vh;
    height: 4vh;
  }

  .tab_three_room {
    width: 41vh;
    height: 4vh;
  }

  .tab_four_room {
    width: 41vh;
    height: 4vh;
  }

  .rooms .pink_logo_ {
    position: relative;
    bottom: 3vh;
  }

  .room_card_bg {
    width: 100%;
    height: 26vh;
  }

  .room_card_bg_two {
    width: 100%;
    height: 26vh;
  }

  .room_card_bg_three {
    width: 100%;
    height: 26vh;
  }

  .room_card_three_inner_header {
    width: 35vh;
  }

  .rooms_btn {
    padding: 2px 13vh 2px 13vh;
  }

  .room_bg {
    width: 85vh;
  }

  .room_bg_twin {
    width: 85vh;
  }

  .room_bg_queen {
    width: 85vh;
  }

  .room_bg_engelli {
    width: 85vh;
  }

  .room_bg_two {
    width: 85vh;
  }

  .room_bg_suite {
    width: 85vh;
  }

  .room_bg_king {
    width: 85vh;
  }

  .room_bg_lounge {
    width: 85vh;
  }

  .room_text_section {
    width: 100%;
    position: relative;
    top: 0vh;
    text-align: start;
  }

  .room_text_section li {
    padding-bottom: 10px;
    font-size: 13px !important;
}

  .room_text_section p{
    font-size: 10px;
  }

  .rooms_icons_section {
    top: 0vh;
  }

  .reset {
    right: 4vh;
    height: 4vh;
  }

  .rooms_icons_section img {
    padding: 3.5vh;
  }

  .item_three_room {
    height: 100%;
  }

  .item_two_room {
    height: 100%;
  }

  .item_one_room {
    height: 100%;
  }

  .rooms_lounge_text_section {
    position: relative;
    left: 9vh;
  }

  .room_inner {
    padding-top: 0;
    position: relative;
    top: -10vh;
    gap: 10px;
  }

  .rooms .services_div {
    padding-top: 0vh !important;
  }
}

@media screen and (max-width: 1440px) {
  .room_card_inner {
    width: 100%;
  }

  .room_card_three_inner_header {
    width: 100%;
  }

  .rooms_btn {
    width: 100%;
    padding: -2px 16vh -2px 16vh;
  }

  .tab_three {
    width: 45vh;
  }

  .item_three_room {
    height: 110%;
   /*  width: 100% !important; */
  }

  .item_one_room {
    height: 110%;
   /*  width: 100% !important; */
  }

  .item_two_room {
    height: 110%;
    /* width: 100% !important; */
  }
}

@media screen and (max-width: 1210px) {
.ihg_one_home{
  width: 80%;
}

  .room_inner {
    display: flex;
    flex-direction: column;
  }

  .rooms .tab_list {
    overflow-x: scroll;
}

.room_text_section_lounge li {
  font-size: 13px;
  padding-bottom: 10px;
}

.rooms {
  padding-top: 20vh;
}

}

@media screen and(max-width: 1023) {

  .rooms {
    padding-top: 20vh !important;
}

  .rooms .tab_list {
    overflow-x: scroll;
}
}

@media screen and (max-width: 992px) {

  .home_tel_link{
    margin-bottom: 3vh;
    font-size: 15px ;
  }

  .services_icon {
    width: 80%;
  }
  
  .text-right {
    margin-left: 0px !important;
  }

  .restaurant_date {
    flex-direction: column;
  }

  .restaurant_date_premium {
    display: block !important;
  }
  .rooms {
    padding-top: 15vh;
  }

  .room_inner {
    padding-top: 0;
    position: relative;
    top: 0vh;
  }

  .room_text_section_lounge {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    position: relative;
    top: -1vh;
    left: 0vh;
    text-align: start;
  }

  .room_text_section_lounge li {
    font-size: 13px;
  }

  .room_text_section li {
    font-size: 13px !important;
  }

  .tab_one_room {
    min-width: 50%;
    height: 3vh;
    padding: 13px 20px 9px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tab_two_room {
    min-width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3vh;
    padding: 13px 20px 9px 20px;
  }

  .tab_three_room {
    min-width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3vh;
    padding: 13px 20px 9px 20px;
  }

  .tab_four_room {
    min-width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3vh;
    padding: 13px 20px 9px 20px;
  }

  .rooms .tab_list {
    overflow-x: scroll;
  }

  .tab_one_room h6 {
    font-size: 14px;
  }

  .tab_two_room h6 {
    font-size: 14px;
  }

  .tab_three_room h6 {
    font-size: 14px;
  }

  .tab_four_room h6 {
    font-size: 14px;
  }

  .rooms_lounge_text_section {
    position: relative;
    left: 0vh;
  }

  .rooms_swiper_mobile_block {
    display: flex;
    gap: 50px;
    padding-top: 2vh;
    width: 100%;
    overflow-x: scroll;
  }

  .rooms_swiper_mobile_block img {
    width: 20%;
  }

  .swiper_box_shadow {
    display: none;
  }

  .css-heg063-MuiTabs-flexContainer {
    overflow-x: scroll !important;
    width: 120%;
  }

  .rooms .services h6 {
    font-size: 13px !important;
  }

  .ihg_one {
    width: 50%;
  }

  .membership_inner {
    text-align: center;
  }

  .tab_one {
    width: 20vh;
    font-size: 17px;
    cursor: pointer;
  }

  .tab_two {
    width: 15vh;
    font-size: 17px;
    cursor: pointer;
  }

  .tab_three {
    width: 30vh;
    font-size: 17px;
    cursor: pointer;
  }

  .tab_header {
    min-height: 15vh;
    display: flex;
    justify-content: space-between;
    padding-left: 2vh;
    padding-right: 2vh;
    padding-top: 2vh;
  }

  .room_card_inner {
    width: 100%;
  }

  .room_card_three_inner_header {
    width: 100%;
  }

  .reset {
    height: 3vh;
    right: 1vh;
    bottom: 0.5vh;
  }

  .room_card_bg {
    width: 100%;
    height: 60vh;
  }

  .room_card_bg_two {
    width: 100%;
    height: 60vh;
  }

  .room_card_bg_three {
    width: 100%;
    height: 60vh;
  }

  .rooms_btn {
    padding: 2px 23vh 2px 23vh;
  }

  .room_standard {
    flex-direction: column;
  }

  .room_twin {
    flex-direction: column;
  }

  .room_two {
    flex-direction: column;
  }

  .room_two_jr {
    flex-direction: column;
  }

  .room_king {
    flex-direction: column;
  }

  .room_suite {
    flex-direction: column;
  }

  .room_lounge {
    flex-direction: column;
  }

  .room_queen {
    flex-direction: column;
  }

  .room_engelli {
    flex-direction: column;
  }

  .room_bg {
    width: 100%;
    height: 15vh;
  }

  .room_bg_twin {
    width: 100%;
    height: 15vh;
  }

  .room_bg_queen {
    width: 100%;
    height: 15vh;
  }

  .room_bg_engelli {
    width: 100%;
    height: 15vh;
  }

  .room_bg_two {
    width: 100%;
    height: 15vh;
  }

  .room_bg_two_jr {
    width: 100%;
    height: 15vh;
  }

  .room_bg_suite {
    width: 100%;
    height: 15vh;
  }

  .room_bg_lounge {
    width: 100%;
    height: 15vh;
  }

  .room_bg_king {
    width: 100%;
    height: 15vh;
  }

  .rooms .swiper {
    width: 61%;
    position: relative;
    right: 14vh;
    top: 1vh;
  }

  .rooms_icons_section img {
    padding: 2vh;
  }

  .rooms_icons_section {
    width: 100%;
    position: relative;
    left: 0vh;
    gap: 30px;
    top: 0vh;
  }

  .room_text_section {
    width: 100%;
    position: relative;
    top: 1vh;
    left: 0vh;
    text-align: start;
    justify-content: space-around;
  }

  .item_one_room {
    width: 90% !important;
    height: 100%;
    background: white;
    box-shadow: 0px 1px 19px -10px gray;
    border-radius: 9px;
    margin-bottom: 5vh;
  }

  .item_two_room {
    width: 90% !important;
    height: 100%;
    background: white;
    box-shadow: 0px 1px 19px -10px gray;
    border-radius: 9px;
    margin-bottom: 5vh;
  }

  .item_three_room {
    width: 100% !important;
    height: 95vh;
    background: white;
    box-shadow: 0px 1px 19px -10px gray;
    border-radius: 9px;
    margin-bottom: 5vh;
  }

  .services_icon {
    padding: 1vh;
  }
}


@media screen and (max-width: 768px) {
  .services_icon_five{
    width: 50% !important;
  }
}


@media screen and (max-width: 568px) {

  .services_icon .services_icon_five {
    width: 70% !important;
  }

  .rooms {
    padding-top: 15vh;
  }

  .tour_gif {
    display: none;
  }

  .tab_one_room h6 {
    font-size: 13px;
  }

  .tab_two_room h6 {
    font-size: 13px;
  }

  .tab_three_room h6 {
    font-size: 13px;
  }

  .tab_four_room h6 {
    font-size: 13px;
  }

  .tab_one_room {
    min-width: 50%;
    height: 3vh;
    padding: 13px 20px 9px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tab_two_room {
    min-width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3vh;
    padding: 13px 20px 9px 20px;
  }

  .tab_three_room {
    min-width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3vh;
    padding: 13px 20px 9px 20px;
  }

  .tab_four_room {
    min-width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3vh;
    padding: 13px 20px 9px 20px;
  }

  .rooms .tab_list {
    overflow-x: scroll;
  }

  .room_inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .room_card_inner h4 {
    font-size: 20px;
  }

  .rooms .room_card p {
    font-size: 13px;
  }

  .services_icon {
    padding: 0vh 18vh;
  }

  .services h4 {
    text-align: center;
  }

  .room_card {
    padding-top: 10vh;
  }

  .rooms_lounge_text_section {
    position: relative;
    width: 50vh;
    left: 0vh;
  }

  .rooms_lounge_text_section p {
    font-size: 11px;
  }

  .rooms_swiper_mobile_block img {
    width: 60%;
  }

  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    font-size: 11px !important;
  }

  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-size: 11px !important;
  }

  .rooms_swiper_mobile_block {
    display: flex;
    gap: 30px;
    padding-top: 0vh;
    width: 100%;
    overflow-x: scroll;
  }

  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    padding: 2px 15px 2px 15px !important;
  }

  .css-heg063-MuiTabs-flexContainer {
    overflow-x: scroll;
    width: 100%;
  }

  .item_one_room {
    width: 100% !important;
    height: 100%;
    background: white;
    box-shadow: 0px 1px 19px -10px gray;
    border-radius: 9px;
    margin-bottom: 5vh;
  }

  .item_two_room {
    width: 100% !important;
    height: 100%;
    background: white;
    box-shadow: 0px 1px 19px -10px gray;
    border-radius: 9px;
    margin-bottom: 5vh;
  }

  .item_three_room {
    width: 100% !important;
    height: 100%;
    background: white;
    box-shadow: 0px 1px 19px -10px gray;
    border-radius: 9px;
    margin-bottom: 5vh;
  }

  .room_card_bg {
    width: 100%;
    height: 53vh;
  }

  .room_card_bg_two {
    width: 100%;
    height: 53vh;
  }

  .room_card_bg_three {
    width: 100%;
    height: 53vh;
  }

  .room_card_inner {
    width: 35vh;
  }

  .room_card_three_inner_header {
    width: 35vh;
  }

  .room_bg {
    display: none;
    width: 40vh;
    height: 15vh;
  }

  .room_bg_twin {
    display: none;

    width: 40vh;
    height: 15vh;
  }

  .room_bg_queen {
    display: none;

    width: 40vh;
    height: 15vh;
  }

  .room_bg_engelli {
    display: none;

    width: 40vh;
    height: 15vh;
  }

  .room_bg_two {
    display: none;

    width: 40vh;
    height: 15vh;
  }

  .room_bg_suite {
    display: none;

    width: 40vh;
    height: 15vh;
  }

  .room_bg_king {
    display: none;

    width: 40vh;
    height: 15vh;
  }

  .room_bg_lounge {
    display: none;

    width: 40vh;
    height: 15vh;
  }

  .rooms_icons_section img {
    padding: 0.5vh;
  }

  .one_icon {
    width: 65%;
  }

  .two_icon {
    width: 80%;
  }

  .three_icon {
    width: 67%;
  }

  .four_icon {
    width: 70%;
  }

  .five_icon {
    width: 79%;
  }

  .rooms_icons_section {
    position: relative;
    left: -1vh;
    gap: 20px;
    top: 3vh;
  }

  .room_text_section {
    width: 100%;
    position: relative;
    top: 5vh;
    left: 0vh;
    text-align: start;
  }

  .tab_three {
    font-size: 15px;
    cursor: pointer;
  }

  .tab_two {
    font-size: 15px;
    cursor: pointer;
  }

  .tab_one {
    font-size: 15px;
    cursor: pointer;
  }

  .reset {
    height: 3.5vh;
    right: 0.5vh;
    bottom: -0.5vh;
  }

  .services_inner {
    display: grid;
    justify-content: space-around;
  }

  .room_text_section p {
    font-size: 11px;
  }

  .rooms .rooms_icons_section h6 {
    font-size: 11px;
  }

  .rooms_btn {
    padding: 2px 13vh 2px 13vh;
    width: 100%;
  }

  .rooms_btn_ihg {
    padding: 2px 5vh 2px 5vh;
    font-size: 13px;
  }

  .ihg_one {
    width: 100%;
  }

  .rooms .pink_logo_ {
    position: relative;
    bottom: 3vh;
  }
}

.room-hover:hover {
  filter: brightness(0.7);
}

.room_text_section li {
  padding-bottom: 10px;
  font-size: 15px;
}

.carousel img {
  cursor: pointer;
}

.carousel-item.active {
  cursor: pointer;
}

.carousel-control-next-icon:after {
  content: "\00BB";
  font-size: 3rem;
}

.carousel-control-prev-icon:after {
  content: "\00AB";
  font-size: 3rem;
}

.services_inner h6 {
  font-size: 13px;
  padding-top: 1vh;
  cursor: pointer;
}

.custom-tooltip {
  width: 300px !important;
  padding: 15px !important;
  box-shadow: 0px 1px 19px -10px gray !important;
  font-size: 13px !important;
}

.restaurant_date_premium {
  display: flex;
  justify-content: start;
  gap: 80px;
}
.restaurant_date_premium h6 {
  font-size: 15px;
}

.text-right {
  margin-left: 52px;
}

.restaurant_button_span {
  margin-left: 0 !important;
}

.restaurant_date h6 {
  font-size: 15px;
}

.restaurant_date {
  display: flex;
  justify-content: space-between;
}
