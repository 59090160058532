.ihg_header_div_container {
  margin-top: 5vh;
}

.istirak {
  color: black;
  font-size: 16px;
  font-weight: 600;
}

.ihg-hotels-resort-section.col-lg-12 {
  border: 1px solid black;
}

.footer nav {
  display: flex;
  justify-content: center;
}

.ihg-hotels-resort-section {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: space-around;
}

.ihg-hotels-resort-section img {
  width: 9.1111%;
  padding: 0.8vw;
}

.footer_swiper_img_ihg_header.footer_swiper_img {
  background-color: white;
  width: 10vw;
}

.ihg_header_div {
  width: 100%;
  display: flex;
  justify-content: start;
  margin-top: -3vw;
}

.ihg_reward_div img {
  background-color: white;
  width: 10vw;
}

.ihg_reward_div {
  width: 100%;
  display: flex;
  justify-content: end;
  margin-bottom: -3vw;
}

.footer_best_price {
  width: 8%;
}

@media screen and (max-width: 991.98px) {
  .footer_best_price {
    width: 13%;
    padding-top: 2vh;
  }
}

@media screen and (max-width: 767.98px) {
  .istirak {
    font-size: 13px;
  }
}
