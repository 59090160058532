.contact {
  background: url(/public/img/crowne_bg.png);
  background-repeat: no-repeat;
  padding-top: 10vh;
  
}

.contact .contact_crowne_logo {
  width: 20%;
}

.contact .directions_map_button {
  text-align: center;
  background-color: var(--pink);
  border-radius: 5px;
  padding: 1px 20px 1px 20px;
}

.contact .directions_map_button a {
  color: white !important;
}

.contact .black_link {
  color: black !important;
}

.contact .direction_icon {
  font-size: 3vh;
  color: white;
}

.contact .tab_section {
  padding-top: 8vh;
  padding-left: 10vh;
  padding-right: 10vh;
}

.contact .social_media {
  padding-top: 3rem;
}

.contact .react-tabs {
  background: white;
  box-shadow: 0px 1px 14px -10px gray;
  padding: 5vh;
  margin-top: 4vh;
}

.contact .tab_section .react-tabs__tab--selected {
  cursor: pointer;
  background: var(--pink);
border-bottom: 1px solid black !important;
  color: black;
  opacity: 1 !important;
  font-weight: 600;
  border-radius: 5px 5px 5px 5px;
  display: flex;
  justify-content: center;
  border-radius: 0;
}

.contact .tab_one {
  border: 1px solid var(--pink);
  color: black;
  margin-right: 2vh;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  width: 25vh;
  font-size: 16px;
  padding: 0 15vh;

  opacity: 0.9;
  cursor: pointer;
}

.contact .tab_two {
  border: 1px solid var(--pink);
  color: black;

  margin-right: 2vh;
  display: flex;
  justify-content: center;
  width: 25vh;
  font-size: 16px;
  padding: 0 5vh;
  opacity: 0.9;
  cursor: pointer;
}
.tab_one_directions {
  border: 1px solid var(--pink);
  color: black;

  margin-right: 2vh;
  display: flex;
  justify-content: center;
  width: 25vh;
  font-size: 15px;
  opacity: 0.9;
  cursor: pointer;
}
.tab_two_directions {
  border: 1px solid var(--pink);
  color: black;

  margin-right: 2vh;
  display: flex;
  justify-content: center;
  width: 25vh;
  font-size: 15px;
  opacity: 1;
  cursor: pointer;
}
.tab_three_directions {
  border: 1px solid var(--pink);
  color: black;
  margin-right: 2vh;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  width: 25vh;
  font-size: 15px;
  opacity: 1;
  cursor: pointer;
}
.tab_four_directions {
  border: 1px solid var(--pink);
  color: black;
  margin-right: 2vh;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  width: 25vh;
  font-size: 15px;
  opacity: 1;
  cursor: pointer;
}

/* .contact h5 {
  color: var(--pink);
} */

.contact .social_media_icon {
  color: #9c0d6b;
  font-size: 2em;
  padding: 0.5vh;
}

.form-control {
  border-radius: 0px !important;
  border: 1px solid transparent;
  border-bottom: 1px solid var(--pink);
  /*   color: darkgray !important; */
}

.form-control:focus {
  border-bottom: 1px solid var(--pink) !important;

  color: #212529;
  background-color: #fff;
  border-color: white;
  outline: 0;
  box-shadow: 0 0 0 0.25rem transparent;
}

.contact .contact_btn {
  border: 1px solid var(--pink);
  border-radius: 5px;
  padding: 0.1vh;
  transition: all 0.3s ease;
}

.contact .contact_btn:hover {
  background-color: var(--pink) !important;
  box-shadow: inset 0px 0 0 0 var(--pink) !important;
  color: white !important;
}

.contact .social_media_icon_contact {
  color: black !important;
  font-size: 3.5vh;
  padding: 5px;
}

.contact .contact_mail_link {
  color: black !important;
}


.form-group {
  position: relative;
  margin-bottom: 20px;
}

.contact_label {
  position: absolute;
  pointer-events: none;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
  transition: 0.2s ease-out;
}

.contact_label:focus ~ label,
textarea:focus ~ label,
input:not(:placeholder-shown) ~ label,
textarea:not(:placeholder-shown) ~ label {
  font-size: 12px;
  top: -9px;
  left: 5px;
  padding: 0 3px;
}

input[type="text"],
input[type="number"],
input[type="email"],
textarea {
  border: 1px solid #9c0d6b;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
}


.field {
  height: 60px;
  border: 1px solid #9c0d6b;
  padding: 15px 20px !important;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  margin-bottom: 2vh;
  border-radius: 5px;
}

/* Style the button and other elements as needed */


.contact .download_sheet .white_link{
color: black !important;
}

@media screen and (max-width: 1708px) {
  .contact .pink_logo_ {
    position: relative;
    bottom: 3vh;
  }

  .contact .tab_section {
    padding-top: 0vh;
    padding-left: 10vh;
    padding-right: 10vh;
    position: relative;
  }

  .contact .social_media {
    padding-top: 0;
  }
  .contact .tab_one {
    width: 45vh;
  }

  .contact .tab_two {
    width: 45vh;
  }
}




@media screen and (max-width: 992px) {

.mobil_center{
  display: flex;
  justify-content: center;
}

  
  .contact .pink_logo_ {
    position: relative;
    bottom: 0vh;
  }

  .contact .tab_section {
    padding-top: 1vh;
    padding-left: 2vh;
    padding-right: 5vh;
    top: 0vh;
  }

  .contact .right_section {
    text-align: center;
  }

  .contact .tab_list {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid var(--black);
    position: relative;
    right: -1vh;
  }

  .contact .tab_two {
    width: 20vh;
    padding: 0 1vh;
  }
  .contact .tab_one {
    width: 20vh;
    padding: 0 1vh;
  }
}

@media screen and (max-width: 768px) {

  .contact .tab_list{
    overflow-x: scroll;
  }

  .mobil_center{
    display: flex;
    justify-content: center;
  }
  .contact .tab_section {
    padding-top: 0vh;
    padding-left: 2vh;
    padding-right: 2vh;
  }
}
@media screen and (max-width: 568px) {
  .contact .pink_logo_ {
    width: 15vh;
    padding-top: 1vh;
    left: 0.5vh;
    bottom: 3vh;
  }

  .contact .tab_list {
    overflow-x: scroll;
  }

  .contact .tab_section {
    padding-top: 0vh;
    padding-left: 0vh;
    padding-right: 0vh;
  }

  .contact .react-tabs {
    background: white;
    box-shadow: 0px 1px 14px -10px gray;
    padding: 2vh;
  }
  .contact .tab_two {
    width: 15vh;
    padding: 0 0vh;
    font-size: 15px;
  }
  .contact .tab_one {
    width: 15vh;
    padding: 0 0vh;
    font-size: 15px;
  }

  .tab_four_directions {
    font-size: 11px;
    margin-right: 2px;
  }
  .tab_three_directions {
    font-size: 11px;
    margin-right: 2px;
  }
  .tab_two_directions {
    font-size: 11px;
    margin-right: 2px;
  }
  .tab_one_directions {
    font-size: 11px;
    margin-right: 2px;
  }
  .contact .form-control {
    font-size: 0.7em !important;
  }

  .contact .social_media_icon {
    font-size: 1.6em;
    padding: 0.5vh;
  }

  .form-floating > label {
    font-size: 12px;
  }

  .tab_four_directions {
    padding: 1px 5px 1px 5px;
  }
  .tab_three_directions {
    padding: 1px 5px 1px 5px;
  }
  .tab_two_directions {
    padding: 1px 5px 1px 5px;
  }
  .tab_one_directions {
    padding: 1px 5px 1px 5px;
  }

  .contact .directions_map_button {
    font-size: 11px;
    padding: 0px 5px 0px 5px;
  }
}

/* 
@media screen and (min-width: 3840px) {

  .header_two_logo{
    width: 100%;
  }
} */