
.portfolio__labels {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 6vh;
    margin-bottom: 5vh;
  
  }
  
  .portfolio__labels a {
   
    font-weight: bold;
    color: #333;
  }
  
  .portfolio__container {
  
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .portfolio__container span {
    border: 1px solid #333;
    padding: 5px;
  }
  .home_gallery{
    width: 20%;
  }

  .portfolio__labels a:hover{
color: #9c0d6b;
  }
  .portfolio__labels a.active{
border-bottom: 1px solid #9c0d6b;
color: #9c0d6b;
  }
  @media screen and (max-width: 767.98px) {

    .portfolio__labels a {
   font-size: 13px;
      font-weight: bold;
      color: #333;
    }
  }