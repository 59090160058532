.meeting_events {
  background: url(/public/img/crowne_bg.png);
  padding-top: 16vh;
}

.meeting_events .meeting_inner {
  cursor: pointer;
  padding-top: 10vh;
  gap: 30px;
}

.meeting_table .table th {
  text-align: start;
  padding: 0.5vh;
}

.meeting_table .table tr:hover {
  scale: 1.02;
  background-color: #9c0d6b8a;
}

.meeting_events .table_two_section tr {
  text-align: justify;
  padding: 0.5vh;
}

.meeting_events .table_two_section tr:hover {
  scale: 1.02;
  background-color: #9c0d6b8a;
}

.meeting_video {
  width: 100%;
}

.meeting_events tbody,
td,
tfoot,
th,
thead,
tr {
  border-style: none !important;
}

.meeting_events tr:hover {
  background-color: var(--light-pink);
}

.meeting_events .table .tr_header {
  margin-bottom: 2vh !important;
}

.meeting_events .table .tr_header:hover {
  background-color: white;
}

.meeting_events .table {
  width: 100% !important;
}

.meeting_events .table_section_title {
  padding-top: 3vh;
  padding-bottom: 3vh;
  padding-left: 1vh;
}

.meeting_events .table_two_section_title {
  padding-top: 3vh;
  padding-bottom: 3vh;
  padding-left: 1vh;
}

.room_tab_detail .react-tabs__tab--selected {
  background-color: var(--pink);
  color: black;
  font-weight: 900;
}
.room_tab_detail .react-tabs__tab--selected h6 {
  background-color: var(--pink);
  color: black;
  font-weight: bold;
}

.tab_one_meet {
  opacity: 0.5;
  font-size: 17px;
  cursor: pointer;
  padding: 5px;
  min-width: 10%;
}

.tab_two_meet {
  width: 30vh;
  font-size: 17px;
  cursor: pointer;
  padding: 2px 20px 9px 20px;
  border: 1px solid var(--pink) !important;
  border-radius: 9px !important;
  margin-right: 2vh !important;
  color: black;
  text-align: center;
}

.tab_three_meet {
  width: 30vh;
  font-size: 17px;
  cursor: pointer;
  padding: 2px 20px 9px 20px;
  border: 1px solid var(--pink) !important;
  border-radius: 9px !important;
  margin-right: 2vh !important;
  color: black;
  text-align: center;
}

.tab_four_meet {
  width: 30vh;
  font-size: 17px;
  cursor: pointer;
  padding: 2px 20px 9px 20px;
  border: 1px solid var(--pink) !important;
  border-radius: 9px !important;
  margin-right: 2vh !important;
  color: black;
  text-align: center;
}

.tab_five_meet {
  width: 30vh;
  font-size: 17px;
  cursor: pointer;
  padding: 2px 20px 9px 20px;
  border: 1px solid var(--pink) !important;
  border-radius: 9px !important;
  margin-right: 2vh !important;
  color: black;
  text-align: center;
}

.tab_six_meet {
  width: 30vh;
  font-size: 17px;
  cursor: pointer;
  padding: 2px 20px 9px 20px;
  border: 1px solid var(--pink) !important;
  border-radius: 9px !important;
  margin-right: 2vh !important;
  color: black;
  text-align: center;
}

.tab_seven_meet {
  width: 30vh;
  font-size: 17px;
  cursor: pointer;
  padding: 2px 20px 9px 20px;

  border: 1px solid var(--pink) !important;
  border-radius: 9px !important;
  margin-right: 2vh !important;
  color: black;
  text-align: center;
}

.meeting_events .table_section {
  padding: 3vh;
  background-color: white;
  border-radius: 9px;
  box-shadow: 0px 1px 19px -10px gray;
  background-color: white;
}

.meeting_events .white_link {
  color: black !important;
}

.pdf_button a {
  color: white !important;
}

.meeting_events .table_two_section {
  padding: 3vh;
  background-color: white;
  border-radius: 9px;
  box-shadow: 0px 1px 19px -10px gray;
  background-color: white;
}

.meeting_events .video_section {
  background-color: white;
  border-radius: 9px;
  box-shadow: 0px 1px 19px -10px gray;
  background-color: white;
}

.video_section_inner {
  position: relative;
}

/* .overlay_two{
 height: 133vh;
  width: 1920px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
 
} */

.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
/*   background-color: rgba(0, 0, 0, 0.5);
 */  padding: 20px;
  color: #fff;
}

.video_text {
  text-align: center;
}

.meeting_video_large {
  filter: brightness(60%);
  width: 100%;
}

.meeting_video_small {
  display: none;
}

.header_two_logo_video {
  filter: brightness(0) invert(1);
  -webkit-filter: brightness(0) invert(1);
}

.meeting_events .video_section .video_section_inner {
  padding: 3vh;
}

.meeting_inner {
  display: flex;
  justify-content: space-around;
}

.meeting_inner_res {
  display: flex;
}

.meeting_events .tab_one {
  width: 35vh;
  font-size: 20px;
  opacity: 0.5;
  cursor: pointer;
}

.meeting_events .tab_two {
  width: 45vh;
  font-size: 20px;
  cursor: pointer;
  opacity: 0.5;
  padding: 0 2vh;
}

.meeting_card_bg {
  border-radius: 5px;
  width: 100%;
  height: 45vh;
  background-size: cover;
  background-position: center;
  background-image: url(/public/img/meeting_angora.png);
}

.wedding_card_bg {
  border-radius: 5px;
  width: 100%;
  height: 45vh;
  background-size: cover;
  background-position: center;
  background-image: url(/public/img/wedding_angora.png);
}

.meeting_bg_angora {
  border-radius: 5px;
  border-radius: 9px;
  background-size: cover;
  background-position: center;
  width: 70vh;
  height: 40vh;
  background-image: url(/public/img/meeting_angora.png);
}

.meeting_bg_ankuva {
  border-radius: 9px;
  background-size: cover;
  background-position: center;
  width: 70vh;
  height: 40vh;
  background-image: url(/public/img/wedding_angora_swiper.jpg);
}

.meeting_bg_ankyra {
  border-radius: 9px;
  background-size: cover;
  background-position: center;
  width: 70vh;
  height: 40vh;
  background-image: url(/public/img/meeting_ankyra.png);
}

.meeting_bg_terra {
  border-radius: 9px;
  background-size: cover;
  background-position: center;
  width: 70vh;
  height: 40vh;
  background-image: url(/public/img/terra_swiper_2.jpg);
}

.meeting_bg_nora {
  border-radius: 9px;
  background-size: cover;
  background-position: center;
  width: 70vh;
  height: 40vh;
  background-image: url(/public/img/nora_swiper.jpg);
}

.meeting_bg_vira {
  border-radius: 9px;
  background-size: cover;
  background-position: center;
  width: 70vh;
  height: 40vh;
  background-image: url(/public/img/mira_swiper.jpg);
}

.meeting_bg_mira {
  border-radius: 9px;
  background-size: cover;
  background-position: center;
  width: 70vh;
  height: 40vh;
  background-image: url(/public/img/vera_swiper_2.jpg);
}

.wedding_bg_angora {
  border-radius: 9px;
  background-size: cover;
  background-position: center;
  width: 70vh;
  height: 40vh;
  background-image: url(/public/img/wedding_angora.png);
}

.meeting_events .scroll-container {
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
}

.meeting_events .swiper {
  width: 71%;
  position: relative;
  right: 5vh;
}

.meeting_events .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.crowne-main img {
  cursor: pointer;
  border-radius: 5px;
  transition: 1s all ease !important;
  display: block;
  width: 45%;
  object-fit: cover;
}

.meeting_events .reset {
  border: 1px solid var(--pink);
  border-radius: 9px;
  height: 3vh;
  padding: 1px;
  position: relative;
  right: 14vh;
}

.react-tabs__tab--selected {
  cursor: pointer;
  background: transparent;
  border: none !important;
  border-color: transparent !important;
  color: #9c0d6b;
  opacity: 1 !important;
  border-radius: 5px 5px 0 0;
}

.meeting_events .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  border: 1px solid var(--pink) !important;
  border-radius: 9px !important;
  padding: 2px 15px 2px 15px !important;
  margin-right: 2vh !important;
  color: black;
}

.meeting_events .meeting_info_section {
  position: relative;
  left: 5.5vh;
  display: flex;
}

.meeting_events .meeting_info_section p {
  font-size: 13px;
}

.meeting_events .meeting_info_section .meeting_info_section_inner {
  padding: 0px;
  border: 1px solid #9c0d6b0a;
  margin-left: 3px;
  width: 20%;
  margin-bottom: 3px;
  background: #80808021;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.meeting_events .meeting_card {
  padding: 40px;
  transition: 1s all ease !important;
}

.meeting_events .meeting_card_inner {
  width: 50vh;
}

.crowne-main .meeting_card .meeting_card_bg {
  transition: 1s all ease;
  -webkit-transition: 1s all ease;
  -moz-transition: 1s all ease;
  -ms-transition: 1s all ease;
  -o-transition: 1s all ease;
}

.crowne-main .meeting_card .wedding_card_bg {
  transition: 1s all ease;
  -webkit-transition: 1s all ease;
  -moz-transition: 1s all ease;
  -ms-transition: 1s all ease;
  -o-transition: 1s all ease;
}

.meeting_events .meeting_card_inner p {
  padding-top: 2vh;
  padding-bottom: 2vh;
  font-size: 14px;
}

.meeting_events .meeting_btn {
  padding: 2px 24vh 2px 24vh;
  width: 100%;
  background-color: #9c0d6b;
  color: white;
}

.close-container-x {
  position: absolute;
  margin: auto;
  width: 50px;
  height: 50px;
  margin-top: 100px !important;
  cursor: pointer;
  top: -4vw;
  right: 1vw;
}

.leftright-x {
  height: 4px;
  width: 50px;
  position: absolute;
  margin-top: 24px;
  background-color: #9c0d6b;
  border-radius: 2px;
  transform: rotate(45deg);
  transition: all 0.3s ease-in;
}

.rightleft-x {
  height: 4px;
  width: 50px;
  position: absolute;
  margin-top: 24px;
  background-color: #9c0d6b;
  border-radius: 2px;
  transform: rotate(-45deg);
  transition: all 0.3s ease-in;
}

.close-x {
  margin: 60px 0 0 5px;
  position: absolute;
}

.close-container-x:hover .leftright-x {
  transform: rotate(-45deg);
  background-color: #9c0d6b;
}

.close-container-x:hover .rightleft-x {
  transform: rotate(45deg);
  background-color: #9c0d6b;
}

.close-container-x:hover label {
  opacity: 1;
}

.tab_header {
  position: relative;
}

.download_sheet {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 1px 19px -10px gray;
  background-color: white;
  padding: 50px 100px 50px 50px;
}

.pdf_button {
  background-color: #9c0d6b;
}

.pdf_button:hover {
  background-color: #9c0d6b;
}

.tecnique_equipments {
  box-shadow: 0px 1px 19px -10px gray;
  background-color: white;
  padding: 50px 100px 50px 50px;
}

.tecnique_equipments li {
  list-style: none;
  margin-bottom: 1vh;
}

@media screen and (min-width: 2560px) {
  .meeting_events .meeting_inner {
    gap: 50px;
  }

  .meeting_events .meeting_btn {
    width: 100%;
  }

  .meeting_events .tab_two {
    font-size: 32px;
  }

  .meeting_events .tab_one {
    font-size: 32px;
  }

  .meeting_info_section_inner h6 {
    font-size: 20px;
  }

  .meeting_events .reset {
    right: -1vh;
  }

  .meeting_events .swiper {
    right: 1vh;
  }

  .meeting_events .meeting_info_section p {
    font-size: 17px;
  }

  .tab_one_meet h6 {
    font-size: 22px;
  }
}

@media screen and (max-width: 1708px) {
  .meeting_events {
    padding-top: 22vh;
  }

  .meeting_events .meeting_inner {
    padding-top: 0vh;
    position: relative;
    gap: 10px;
  }

  .meeting_events .meeting_card_inner {
    width: 100%;
  }

  .meeting_card_bg {
    width: 100%;
  }

  .wedding_card_bg {
    width: 100%;
  }

  .meeting_events .meeting_btn {
    padding: 2px 29vh 2px 29vh;
    width: 100%;
  }

  .meeting_bg_angora {
    width: 80vh;
    height: 50vh;
  }

  .meeting_bg_ankuva {
    width: 80vh;
    height: 50vh;
  }

  .meeting_bg_ankyra {
    width: 80vh;
    height: 50vh;
  }

  .meeting_bg_terra {
    width: 80vh;
    height: 50vh;
  }

  .meeting_bg_nora {
    width: 80vh;
    height: 50vh;
  }

  .meeting_bg_vira {
    width: 80vh;
    height: 50vh;
  }

  .meeting_bg_mira {
    width: 80vh;
    height: 50vh;
  }

  .meeting_events .reset {
    height: 4vh;
  }

  .wedding_bg_angora {
    width: 80vh;
    height: 50vh;
  }
}

@media (min-width: 1023.98px) and (min-height: 799.98px) {
  .close-container-x {
    margin-top: 50px;
  }
}

@media screen and (max-width: 1210px) { 
  .close-container-x {
    margin-top: 55px !important;
}

  .meeting_events .meeting_inner {
    padding-top: 0vh;
    position: relative;
    top: 0vh;
  }

  .meeting_card_bg {
    height: 33vh;
  }

  .wedding_card_bg {
    height: 33vh;
  }

  .meeting_events .meeting_table {
    overflow-x: scroll;
  }

  .meeting_events .room_tab_detail .tab_list {
    overflow-x: scroll;
  }

  .tab_one_meet {
    font-size: 17px;
    cursor: pointer;
    padding: 5px;
    min-width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3vh;
    padding: 13px 20px 9px 20px;
  }

  .meeting_events .reset {
    height: 3vh;
    right: 1vh;
    bottom: 0.5vh;
  }

  .meeting_events .meeting_info_section {
    position: relative;
    left: 0vh;
margin-top: 5vh;
    display: flex;
  }

  .meeting_inner_res {
    display: flex;
    flex-direction: column;
  }

  .meeting_bg_angora {
    width: 55vh;
    height: 15vh;
  }

  .meeting_bg_ankuva {
    width: 55vh;
    height: 15vh;
  }

  .meeting_bg_ankyra {
    width: 55vh;
    height: 15vh;
  }

  .meeting_bg_terra {
    width: 55vh;
    height: 15vh;
  }

  .meeting_bg_nora {
    width: 55vh;
    height: 15vh;
  }

  .meeting_bg_vira {
    width: 55vh;
    height: 15vh;
  }

  .meeting_bg_mira {
    width: 55vh;
    height: 15vh;
  }

  .wedding_bg_angora {
    width: 55vh;
    height: 15vh;
  }

  .meeting_inner {
    display: flex;
    flex-direction: column;
  }

  .meeting_card_bg {
    width: 100%;
  }

  .wedding_card_bg {
    width: 100%;
  }

  .meeting_events .meeting_btn {
    padding: 2px 22vh 2px 22vh;
    width: 100%;
  }
}

@media screen and (max-width: 991.98px) {
  .meeting_events {
    padding-top: 15vh;
  }

  .meeting_card_bg {
    height: 65vh;
  }

  .wedding_card_bg {
    height: 65vh;
  }

  .close-container-x {
    margin-top: 40px;
  }

  .download_sheet {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    box-shadow: 0px 1px 19px -10px gray;
    background-color: white;
    padding: 50px 100px 50px 50px;
  }
}

@media screen and (max-width: 767.98px) {

  .meeting_events .meeting_info_section .meeting_info_section_inner h6{
    font-size: 13px;
  }

  .meeting_events .meeting_card_inner {
    width: 45vh;
  }

  .tab_one_meet h6{
    font-size: 15px;
  }

  .meeting_events .tab_one {
    font-size: 17px;
}
  .meeting_events .tab_two {
    font-size: 17px;
}

  .rightleft-x {
    width: 30px;
  }

  .leftright-x {
    width: 30px;
  }
}

@media screen and (max-width: 567.98px) {

  .close-container-x {
    margin-top: 20px;
  }
  .tecnique_equipments p {
    font-size: 13px;
  }
  .tecnique_equipments li {
    font-size: 13px;
  }
  .tecnique_equipments {
    padding: 50px 10px 10px 20px;
  }
  .meeting_video_large {
    display: none;
  }

  .meeting_video_small {
    display: block;
    height: 100vh;
  }

  .meeting_events .meeting_card_inner {
    width: 35vh;
  }

  .download_sheet h5 {
    font-size: 19px;
  }

  .download_sheet p {
    font-size: 13px;
  }

  .meeting_card_bg {
    width: 100%;
    height: 56vh;
  }

  .wedding_card_bg {
    width: 100%;
    height: 56vh;
  }

  .meeting_events .meeting_btn {
    padding: 2px 13vh 2px 13vh;
  }

  .meeting_bg_angora {
    display: none;
  }

  .meeting_bg_ankuva {
    display: none;
  }

  .meeting_bg_ankyra {
    display: none;
  }

  .meeting_bg_terra {
    display: none;
  }

  .meeting_bg_nora {
    display: none;
  }

  .meeting_bg_vira {
    display: none;
  }

  .meeting_bg_mira {
    display: none;
  }

  .wedding_bg_angora {
    display: none;
  }

  .meeting_events .meeting_info_section .meeting_info_section_inner {
    position: relative;
    width: 26%;
    padding: 8px;
  }

  .meeting_info_section_inner h6 {
    font-size: 10px;
    text-align: start;
  }

  .meeting_events .meeting_info_section p {
    font-size: 13px;
  }

  .meeting_events .tab_two {
    font-size: 13px;
  }

  .meeting_events .tab_one {
    font-size: 13px;
  }

  .tab_one_meet {
    font-size: 17px;
    cursor: pointer;
    padding: 5px;
    min-width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3vh;
    padding: 13px 20px 9px 20px;
  }

  .tab_one_meet h6 {
    font-size: 13px;
  }

  .meeting_events .table_section_title {
    font-size: 19px !important;
  }

  .meeting_events .table_two_section_title {
    font-size: 19px !important;
  }

  .meeting_events .table_two_section {
    overflow-x: scroll;
  }

  .download_sheet {
    padding-left: 2vh;
  }
}
